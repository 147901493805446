import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";
import moment from "moment";

import {
  Button,
  H1,
  H6,
  PageHeader,
  Section,
  SEO,
  RichText,
  FadeGroup,
} from "components";

import { serializer, linkResolver } from "utils";

function NewsPostPage({ data, preview }) {
  let page = data && JSON.parse(data.prismicNewsPost.dataString);
  if (preview) {
    page = preview;
  }
  return (
    <>
      <SEO
        image={page.hero_image && page.hero_image.url}
        title={page.title && PrismicRichText.asText(page.title)}
        description={page.excerpt && PrismicRichText.asText(page.excerpt)}
      />
      {page.canonical && page.canonical.url && (
        <Helmet>
          <link rel="canonical" href={page.canonical.url} />
        </Helmet>
      )}
      {page.hero_image && page.hero_image.url && (
        <PageHeader
          bg="brand.navy"
          src={preview ? page.hero_image && page.hero_image.url : undefined}
          fluid={
            preview
              ? undefined
              : data.prismicNewsPost.data.hero_image &&
                data.prismicNewsPost.data.hero_image.fluid
          }
          alt={page.hero_image && page.hero_image.alt}
          contain={true}
        />
      )}
      <Section pt={2} pb={2} bg={["brand.navy", "bg.default"]}>
        <div
          css={`
            -ms-grid-column: 3;
            -ms-grid-column-span: 19;
            grid-column: 3 / 11;
            -ms-grid-row: 1;
            grid-row: 1;
            ${(props) => props.theme.mediaQueries.medium} {
              -ms-grid-column: 1;
              -ms-grid-column-span: 15;
              grid-column: 1 / 9;
            }
            ${(props) => props.theme.mediaQueries.small} {
              -ms-grid-column: 1;
              -ms-grid-column-span: 7;
              grid-column: 1 / 5;
            }
          `}
        >
          <FadeGroup>
            <H1
              children={page.title && PrismicRichText.asText(page.title)}
              mb="0.5em"
              textAlign="center"
              color={["text.reverse", "text.default"]}
            />
            {page.post_date && (
              <H6
                children={moment(page.post_date).format("MMMM DD, YYYY")}
                textAlign="center"
                color={["text.reverse", "text.default"]}
              />
            )}
          </FadeGroup>
        </div>
      </Section>
      <Section
        pb={3}
        css={`
          ${(props) => props.theme.mediaQueries.small} {
            padding-top: 15vw;
          }
        `}
      >
        <div
          css={`
            -ms-grid-column: 3;
            -ms-grid-column-span: 19;
            grid-column: 3 / 11;
            -ms-grid-row: 1;
            grid-row: 1;
            ${(props) => props.theme.mediaQueries.medium} {
              -ms-grid-column: 1;
              -ms-grid-column-span: 15;
              grid-column: 1 / 9;
            }
            ${(props) => props.theme.mediaQueries.small} {
              -ms-grid-column: 1;
              -ms-grid-column-span: 7;
              grid-column: 1 / 5;
            }
          `}
        >
          <RichText
            children={
              page.body &&
              PrismicRichText.render(page.body, linkResolver, serializer)
            }
            css={`
              a {
                color: ${(props) => props.theme.colors.brand.rose};
              }
            `}
          />
          <div
            css={`
              display: flex;
              justify-content: center;
              margin-top: 5vw;
            `}
          >
            <Button
              children={`Back to News`}
              as={Link}
              to="/news-and-resources/news/"
              variant="peach"
            />
          </div>
        </div>
      </Section>
    </>
  );
}

export default NewsPostPage;

export const query = graphql`
  query ($uid: String!) {
    prismicNewsPost(uid: { eq: $uid }) {
      prismicId
      dataString
      data {
        hero_image {
          fluid(maxWidth: 1920) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
  }
`;
